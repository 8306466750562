
import { Inject } from 'inversify-props';
import { Prop, Vue, Component } from 'vue-property-decorator';
import moment from 'moment';
import EventsModel from '@/modules/events/models/events.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import USER_LEVELS from '@/modules/user/constants/user-levels.constant';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';

@Component({
    components: { Flag, EventTypeIcon },
})
export default class PopupEvents extends Vue {
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({
        required: true,
        type: Array as () => EventsModel[],
    })
    public eventsData!: EventsModel[];

    public get isEventsAvailable() {
        return this.userService.isHotelUser
            || this.userService.isCarUser
            || this.userService.isChainUser
            || this.userService.isClusterUser;
    }

    public get eventsRoute() {
        const { isCarUser } = this.userService;
        const routeBranch = this.$route.path.split('/')[1]!;

        return {
            name: isCarUser
                ? 'events-manager'
                : `${routeBranch}.events-manager`,
            params: {
                ...this.$route.params,
            },
        };
    }

    public openEvent(eventId: string) {
        const { isCarUser } = this.userService;
        const baseRoute = 'events-manager.day-event-modal.view';

        const routeBranch = this.$route.path.split('/')[1]!;

        this.$router.push({
            name: isCarUser
                ? baseRoute
                : `${routeBranch}.${baseRoute}`,
            params: {
                ...this.$route.params,
                eventId,
            },
        });
    }

    public date(event: EventsModel): string {
        if (!event.startDate || !event.endDate) {
            return '';
        }

        if (event.startDate.getTime() === event.endDate.getTime()) {
            return moment(event.startDate).format('DD MMM');
        }

        const endDay = moment(event.endDate).date();
        const endMonth = moment(event.endDate).format('MMM');
        const startDay = moment(event.startDate).date();
        const startMonth = moment(event.startDate).format('MMM');

        const startMonthS = startMonth === endMonth
            ? ''
            : ` ${startMonth}`;
        return `${startDay}${startMonthS}-${endDay} ${endMonth}`;
    }
}
