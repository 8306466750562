
import { Component, Prop, Vue } from 'vue-property-decorator';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import ToUppercase from '@/modules/common/filters/to-uppercase.filter';

@Component({
    filters: { PercentFilter, PriceFilter, ToUppercase },
})
export default class Calendar extends Vue {
    @Prop({ type: Number, required: true })
    public year!: Year;
    @Prop({ type: Number, required: true })
    public month!: Month;
    @Prop({ type: Boolean, required: false })
    public borders!: boolean;

    day(dayIndex: number): Day {
        return (dayIndex - this.firstDayIndexOfWeek) as Day;
    }

    get daysCount(): number {
        return this.firstDayIndexOfWeek + this.lastDayCurrentMonth >= 35 ? 42 : 35;
    }

    get firstDayIndexOfWeek(): number {
        return new Date(this.year, this.month).getDay() - 1;
    }

    get lastDayCurrentMonth(): Day {
        return new Date(this.year, this.month + 1, 0).getDate() as Day;
    }

    get lastDayMonthBefore(): Day {
        return new Date(this.year, this.month, 0).getDate() as Day;
    }

    dayBeforeOrAfter(dayIndex: number): Day {
        return dayIndex >= this.lastDayCurrentMonth ? this.getDayAfter(dayIndex) : this.getDayBefore(dayIndex);
    }

    getDayBefore(dayIndex: number): Day {
        return (this.lastDayMonthBefore - (this.firstDayIndexOfWeek - dayIndex)) as Day;
    }

    getDayAfter(dayIndex: number): Day {
        const lastDayCurrent = new Date(this.year, this.month + 1, 0).getDate();
        return (dayIndex - this.firstDayIndexOfWeek - lastDayCurrent) as Day;
    }
}
